import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header.jsx';
import './VideoLink.scss';
import { Image } from 'cloudinary-react';

const cloudName = 'dohyiersk';

function VideoLink() {
  const [data, setData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://adriengonon.fr/backend/wordpress/wp-json/wp/v2/video/${id}?acf_format=standard`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des données vidéo", error);
        navigate('/Error404');
      });
  }, [id, navigate]);

  if (!data) {
    return <p>Chargement...</p>;
  }

// Utiliser le titre ou data_public_id pour le public_id en fonction de la disponibilité
const publicId = data.acf.img.public_id.title 
  ? `portfolio/${data.acf.img.public_id.title}` 
  : `portfolio/${data.acf.data_public_id}`;

// Convertir l'objet youtubelinks en tableau et filtrer les valeurs vides
const youtubeLinks = Object.values(data.acf.youtubelinks).filter(link => link);

// Créer un tableau des images img1 à img5
const imageFields = ['img1', 'img2', 'img3', 'img4', 'img5'];
const validImages = imageFields
  .map((field) => data.acf.link && data.acf.link[field] && data.acf.link[field].title 
    ? `portfolio/${data.acf.link[field].title}` 
    : null)
  .filter((img) => img !== null);  // Filtrer pour ne garder que celles qui existent

// Si aucune image valide n'est trouvée, on utilise le publicId par défaut
const imageToDisplay = validImages.length > 0 ? validImages : [publicId];

return (
  <div>
    <Header />
    <main className="main-video">
      <div className="video-text">
        <h1 className="video-title">{data.acf.img.title}</h1>
        <p className="video-description">{data.acf.img.description}</p>
      </div>

      {/* Affichage des images img1 à img5, si elles existent */}
      {validImages.length > 0 ? (
        <div className="image-gallery">
          {validImages.map((img, index) => {
            const link = data.acf.link[`url${index + 1}`];
            const title = data.acf.link[`title${index + 1}`];
            return (
              <div className={`image-item image-item-${index + 1}`} key={index}>
                <a target="_blank" rel="noreferrer" href={link}>
                  <Image
                    cloudName={cloudName}
                    publicId={img}
                    width="auto"
                    height="1000"
                    crop="scale"
                    alt={`Image ${index + 1}`}
                    className="thumbnailInsta"
                  />
                </a>
                {link && title && (
                  <a target="_blank" rel="noreferrer" className="button" href={link}>
                    {title}
                  </a>
                )}
              </div>
            );
          })}
        </div>
      ) : youtubeLinks.length === 0 ? ( // N'afficher l'image par défaut que si aucun lien YouTube
        <div className="video-part">
          <a target="_blank" rel="noreferrer" href={data.acf.link.url1}>
            <Image
              cloudName={cloudName}
              publicId={imageToDisplay[0]}
              width="auto"
              height="1000"
              crop="scale"
              alt={data.acf.img.title}
              className="thumbnail"
            />
          </a>
          {/* Affichage des boutons correspondant aux URLs et titres (url1 à url5) */}
          {imageFields.map((field, index) => {
            const url = data.acf.link[`url${index + 1}`];
            const title = data.acf.link[`title${index + 1}`];
            if (url && title) {
              return (
                <a target="_blank" rel="noreferrer" className="button" href={url} key={index}>
                  {title}
                </a>
              );
            }
            return null;
          })}
        </div>
      ) : null}

      {/* Affichage des vidéos YouTube */}
      {youtubeLinks.length > 0 && (
        <div className="video-part display-unset">
          {youtubeLinks.map((youtubeLink, index) => (
            <div className='video-container' key={index}>
              <iframe
                width="1200"
                height="675"
                src={`${youtubeLink}?rel=0`}
                title={`YouTube Video ${index + 1}`}
                frameBorder="0"
                allow="autoplay; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>
      )}
    </main>
    <Footer />
  </div>
);


}

export default VideoLink;
